import React from 'react';
import "./MQApp.css";

function Pair({ pair, options, questionIndex, pairIndex, onAnswerChange, userAnswer, isSubmitted }) {
  const handleSelectChange = (e) => {
    onAnswerChange(questionIndex, pairIndex, parseInt(e.target.value, 10));
  };

  const isCorrect = isSubmitted && userAnswer === pair.correctAnswer;

  return (
    <div className="pair">
      {/* Conditionally apply strike-through styling */}
      <span style={{color: isCorrect ? 'Green' : 'Black'}}>{pair.toMatch}</span>
      <select onChange={handleSelectChange} disabled={isSubmitted} style={{display: isSubmitted ? 'none' : 'block'}}>
        <option value="">Select an option</option>
        {options.map((option, index) => (
          <option key={index} value={index} selected={isSubmitted && userAnswer === index}>
            {option}
          </option>
        ))}
      </select>

      {/* Display the correct answer directly if the response was incorrect */}
      {isSubmitted && (
        <div style={{ color: 'red', margin: '0 5px', fontSize: '12px' }}>
          {isCorrect ? '✔️' : '❌'} <strong>{!isCorrect ? options[pair.correctAnswer] : ''}</strong>
        </div>
      )}
    </div>
  );
}

export default Pair;
