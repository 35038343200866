import React, { useState } from 'react';
import { questions } from './MQuestions';
import MatchingQuestion from './MatchingQuestion';
import "./MQApp.css";

function MQApp() {
  const [responses, setResponses] = useState({});
  const [scoreMQ, setScoreMQ] = useState(null);

  const handleMQAnswerChange = (questionIndex, pairIndex, selectedOption) => {
    setResponses((prev) => ({
      ...prev,
      [questionIndex]: {
        ...(prev[questionIndex] || {}),
        [pairIndex]: selectedOption,
      }
    }));
  };

  const handleMQSubmit = () => {
    let correctCount = 0;
    questions.forEach((question, questionIndex) => {
      question.pairs.forEach((pair, pairIndex) => {
        if (responses[questionIndex]?.[pairIndex] === pair.correctAnswer) {
          correctCount += 1;
        }
      });
    });
    setScoreMQ(correctCount);
  };

  const handleRetry = () => {
    setResponses({});
    setScoreMQ(null);
  };

  return (
    <div className="MQApp">
      <h3>Matching Exam</h3>
      {questions.map((question, index) => (
        <div className="question-card" key={index}>
          <MatchingQuestion
            question={question}
            questionIndex={index}
            onAnswerChange={handleMQAnswerChange}
            responses={responses}
            isSubmitted={scoreMQ !== null}
          />
        </div>
      ))}
      <button onClick={handleMQSubmit} className='MQSubmit' disabled={scoreMQ !== null}>
        Submit
      </button>
      {scoreMQ !== null && (
        <div className="score-section">
          <h2>Your score: {scoreMQ} / {questions.reduce((acc, q) => acc + q.pairs.length, 0)}</h2>
          <button onClick={handleRetry} className='MQRetry'>Retry</button>
        </div>
      )}
    </div>
  );
}

export default MQApp;
