import React from "react";
import "../App.css";

const StartMenu = ({ handleStart, handleNavigateToMatchingQuestion, reviewerType }) => {
    return (
        <>
            <h3>Choose Question Type</h3>
            {
                reviewerType === "SuiteFoundation" ?
                    <div className="start-menu">
                        <button className="start-button" onClick={() => handleStart(80)}>80 Questions - Multiple Choice</button>
                        <br />
                        <button className="start-button" onClick={() => handleStart("all")}>All Questions - Multiple Choice</button>
                        <br />
                        <button className="start-button" onClick={handleNavigateToMatchingQuestion}>Matching Questions</button>
                    </div>
                    : reviewerType === "Application Developer" ?
                        <div className="start-menu">
                            <button className="start-button" onClick={() => handleStart(20)}>20 Questions - Multiple Choice</button>
                            <br />
                            <button className="start-button" onClick={() => handleStart("all")}>All Questions - Multiple Choice</button>
                        </div>
                        : null
            }

        </>
    );
};

export default StartMenu;