import React from "react";
import "../App.css";

const ScoreDisplay = ({ percentageScore, score, totalQuestions, minutes, seconds, isAllQuestionsMode }) => {
  return (
    <div className="score-display">
      <h5>
        Current Score:{" "}
        <span className={percentageScore < 60 ? `low` : percentageScore < 80 ? `medium` : `high`}>
          {" "}
          {percentageScore}%
        </span><br /><br />
        Total Correct Answers: <span>{score}/{totalQuestions}</span><br /><br />
        {!isAllQuestionsMode && (
          <span>
            Time Remaining: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </span>
        )}
      </h5>
    </div>
  );
};

export default ScoreDisplay;