import React from 'react';
import Pair from './Pair';
import "./MQApp.css";

function MatchingQuestion({ question, questionIndex, onAnswerChange, responses, isSubmitted }) {
  return (
    <div className="question">
      <strong>{question.instruction}</strong>
      {question.pairs.map((pair, pairIndex) => (
        <Pair
          key={pairIndex}
          pair={pair}
          options={question.options}
          questionIndex={questionIndex}
          pairIndex={pairIndex}
          onAnswerChange={onAnswerChange}
          userAnswer={responses[questionIndex]?.[pairIndex]}
          isSubmitted={isSubmitted}
        />
      ))}
    </div>
  );
}

export default MatchingQuestion;
