import React from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const Popup = ({ showPopup, popupMessage, popupType, closePopup }) => (
    showPopup ? (
        <div className="popup">
            <div className={`popup-content fade-in ${popupType}`}>
                <span className="close" onClick={closePopup}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                </span>
                <p
                    dangerouslySetInnerHTML={{
                        __html: popupMessage.replace(/\n/g, "<br />"),
                    }}
                />
            </div>
        </div>
    ) : null
);

export default Popup;
