import React from "react";
import "../App.css";

const MainMenu = ({ handleReviewerType }) => (
    <>
    <h3>Choose Reviewer</h3>
        <div className="start-menu">
            <button className="start-button" onClick={() => handleReviewerType("SuiteFoundation")}>SuiteFoundation</button>
            <br />
            <button className="start-button" onClick={() => handleReviewerType("Application Developer")}>Application Developer</button>
        </div>
    </>
);

export default MainMenu;