import React from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const Quiz = ({ currentQuestion, currentQuestionIndex, selectedAnswers, isMultipleAnswers, handleCheckboxChange, handleRadioChange, handleSubmit, isAnswerSelected }) => {
    return (
        <div className="question-container">
            <h2>
                {currentQuestionIndex + 1}. {currentQuestion.question}{" "}
                {isMultipleAnswers && (
                    <span>(Choose {currentQuestion.answer.length})</span>
                )}
            </h2>
            <form className="options">
                {currentQuestion.options.map((option, index) => {
                    const isSelected = (
                        selectedAnswers[currentQuestionIndex] || []
                    ).includes(index);
                    return (
                        <div className="optionsWrapper" key={index}>
                            {isMultipleAnswers ? (
                                <>
                                    <input type="checkbox" id={`option-${index}`} checked={isSelected} onChange={() => handleCheckboxChange(index)} />
                                    <label htmlFor={`option-${index}`}>{option}</label>
                                </>
                            ) : (
                                <>
                                    <input type="radio" name={`question-${currentQuestionIndex}`} id={`option-${index}`} checked={isSelected} onChange={() => handleRadioChange(index)} />
                                    <label htmlFor={`option-${index}`}>{option}</label>
                                </>
                            )}
                        </div>
                    );
                })}
            </form>
            <button
                className="submit-button"
                onClick={handleSubmit}
                disabled={!isAnswerSelected}
            >
                <FontAwesomeIcon icon={faCheckCircle} /> Submit
            </button>
        </div>
    );
};

export default Quiz;