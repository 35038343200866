export const questions = [
    {
        "instruction": "Match the desired requirement to the most appropriate custom Field Type to use. Answer options may be used more than once or not at all.",
        "options": ["Radio Button", "Multiple Select", "Text Area", "Check Box", "List/Record", "Rich Text"],
        "pairs": [
            {
                "toMatch": "Record formatted text",
                "correctAnswer": 5
            },
            {
                "toMatch": "Describe something as On or Off",
                "correctAnswer": 3
            },
            {
                "toMatch": "Connect Customers to a custom record",
                "correctAnswer": 4
            },
            {
                "toMatch": "Record only one of several related preferences",
                "correctAnswer": 4
            },
            {
                "toMatch": "Ability to possibly record several related preferences.",
                "correctAnswer": 1
            }
        ]
    },
    {
        "instruction": "Order (1,2,3) the sequence that should be followed to create a custom record.",
        "options": ["1", "2", "3"],
        "pairs": [
            {
                "toMatch": "Create custom fields ",
                "correctAnswer": 2
            },
            {
                "toMatch": "Create necessary subtabs ",
                "correctAnswer": 1
            },
            {
                "toMatch": "Define record attributes and behavior first",
                "correctAnswer": 0
            }
        ]
    },
    {
        "instruction": "Match the desired Form Customization actions with the correct options. Answer options may be used more than once or not at all.",
        "options": ["Field Group", "Use for pop-ups", "Mandatory", "Store form with record", "Enable field editing on lists"],
        "pairs": [
            {
                "toMatch": "Enable inline editing",
                "correctAnswer": 4
            },
            {
                "toMatch": "Force a user to fill in a field",
                "correctAnswer": 2
            },
            {
                "toMatch": "Enter information on-the-fly instead of a regular screen",
                "correctAnswer": 1
            },
            {
                "toMatch": "Fields are presented in the same order each time a record is accessed",
                "correctAnswer": 3
            }
        ]
    },
    {
        "instruction": "Match the Sales Transaction to the default Customer Stage associated with it. (Answer options may be used more than once or not at all)",
        "options": ["Lead", "Prospect", "Customer"],
        "pairs": [
            {
                "toMatch": "First Invoice",
                "correctAnswer": 2
            },
            {
                "toMatch": "First Opportunity",
                "correctAnswer": 1
            },
            {
                "toMatch": "First Sales Order",
                "correctAnswer": 2
            },
            {
                "toMatch": "First Quote / Estimate",
                "correctAnswer": 1
            },
            {
                "toMatch": "Additional Sales Order",
                "correctAnswer": 2
            }
        ]
    },
    {
        "instruction": "Match the search method with the definition. (Answer options may be used only once)",
        "options": ["Can be used to search Help Topics", "Custom filters can be used", "General or Transaction type only"],
        "pairs": [
            {
                "toMatch": "Quick Search",
                "correctAnswer": 2
            },
            {
                "toMatch": "Global Search",
                "correctAnswer": 0
            },
            {
                "toMatch": "Saved Searches",
                "correctAnswer": 1
            }
        ]
    },
    {
        "instruction": "Match the Report Builder Interface elements to its functionality. Answer options are used once each.",
        "options": ["Filters", "Sorting", "More Options", "Edit Columns"],
        "pairs": [
            {
                "toMatch": "Select report access by roles, departments, groups and employees",
                "correctAnswer": 2
            },
            {
                "toMatch": "Select fields to limit results",
                "correctAnswer": 0
            },
            {
                "toMatch": "Delete or move data, add formulas and define formatting",
                "correctAnswer": 3
            },
            {
                "toMatch": "Have additional ranking options to display parent/child relationship",
                "correctAnswer": 1
            }
        ]
    },
    {
        "instruction": "Match the information logged with where the information is found. (Use each options once)",
        "options": ["Transaction Numbering Audit Log", "System Notes", "My Login Audit", "Transaction Audit Trail", "Login Audit Trail"],
        "pairs": [
            {
                "toMatch": "Security Challenge",
                "correctAnswer": 4
            },
            {
                "toMatch": "Date Allocated",
                "correctAnswer": 0
            },
            {
                "toMatch": "Login Failures Today",
                "correctAnswer": 2
            },
            {
                "toMatch": "Old and New GL Impact",
                "correctAnswer": 1
            },
            {
                "toMatch": "CREATE, CHANGE, DELETE",
                "correctAnswer": 3
            }
        ]
    },
    {
        "instruction": "Identify whether the following is a saved search or custom report?",
        "options": ["Reports", "Saved Search"],
        "pairs": [
            {
                "toMatch": "Allow web query",
                "correctAnswer": 0
            },
            {
                "toMatch": "Basis of custom KPIs",
                "correctAnswer": 1
            },
            {
                "toMatch": "Hierarchical grouping",
                "correctAnswer": 0
            },
            {
                "toMatch": "Dynamical list of results",
                "correctAnswer": 1
            },
            {
                "toMatch": "Custom formula",
                "correctAnswer": 1
            }
        ]
    },
    {
        "instruction": "Match the record with its characteristic:",
        "options": ["Sales Order", "Opportunity", "Estimate/Quote"],
        "pairs": [
            {
                "toMatch": "Has expiration",
                "correctAnswer": 2
            },
            {
                "toMatch": "Item field is not required",
                "correctAnswer": 1
            },
            {
                "toMatch": "Internal document only",
                "correctAnswer": 0
            }
        ]
    },
    {
        "instruction": "Match the following:",
        "options": ["Quantity Pricing", "Absolute Pricing", "Multi-Pricing", "Custom Pricing"],
        "pairs": [
            {
                "toMatch": "Use price level",
                "correctAnswer": 2
            },
            {
                "toMatch": "Always available",
                "correctAnswer": 3
            },
            {
                "toMatch": "Use price schedule",
                "correctAnswer": 0
            },
            {
                "toMatch": "Specific to item and customers",
                "correctAnswer": 1
            }
        ]
    },
    {
        "instruction": "Match the following item type with their characteristic:",
        "options": ["Assembly Item", "Kit/Package Item", "Item Group", "Non-inventory item"],
        "pairs": [
            {
                "toMatch": "Revenue is recognized is at parent, but cost is components",
                "correctAnswer": 1
            },
            {
                "toMatch": "Pricing is dependent on components",
                "correctAnswer": 2
            },
            {
                "toMatch": "Parent is counted as inventory",
                "correctAnswer": 0
            },
            {
                "toMatch": "Can be converted to an inventory item",
                "correctAnswer": 3
            }
        ]
    },
    {
        "instruction": "Match the following:",
        "options": ["Custom Report", "Saved Search", "Global Search", "Quick Search"],
        "pairs": [
            {
                "toMatch": "Hierarchical presentation",
                "correctAnswer": 0
            },
            {
                "toMatch": "Uses keywords",
                "correctAnswer": 2
            },
            {
                "toMatch": "Uses a portlet",
                "correctAnswer": 3
            },
            {
                "toMatch": "Results use criteria set",
                "correctAnswer": 1
            }
        ]
    }
]