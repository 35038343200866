import React, { useState, useEffect } from "react";
import "./App.css";
import MainMenu from "./components/MainMenu";
import StartMenu from "./components/StartMenu";
import Quiz from "./components/Quiz";
import MQ from "./components/MatchingQuestion/MQ";
import ScoreDisplay from "./components/ScoreDisplay";
import Popup from "./components/Popup";
import SuiteFoundationQuestions from "./data/SuiteFoundationQuestions.json";
import ApplicationDeveloperQuestions from "./data/ApplicationDeveloperQuestions.json";

const App = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [score, setScore] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [quizStarted, setQuizStarted] = useState(false);
  const [timer, setTimer] = useState(4800);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [showMatchingQuestion, setShowMatchingQuestion] = useState(false);
  const [isAllQuestionsMode, setIsAllQuestionsMode] = useState(false);
  const [reviewerType, setReviewerType] = useState("");

  const handleReviewerType = (rType) => {
    console.log(`rType: ${rType}, reviewerType: ${reviewerType}`)
    if (rType === "SuiteFoundation") {
      setReviewerType("SuiteFoundation");
    } else if (rType === "Application Developer") {
      setReviewerType("Application Developer");
    } else {
      setReviewerType("");
    }
  };

  const QuestionType = (questionType) => {
    let qType = [];
    if (questionType === 'SuiteFoundation') {
      qType = [...SuiteFoundationQuestions]
    }
    else if (questionType === 'Application Developer') {
      qType = [...ApplicationDeveloperQuestions]
    } else {
      qType = [];
    }
    return qType;
  }

  const randomizeQuestions = (num) => {
    const randomizedQuestions = QuestionType(reviewerType)
      .sort(() => Math.random() - 0.5)
      .slice(0, num);

    return randomizedQuestions.map((question) => {
      const options = question.options;
      const correctAnswerIndices = question.answer || [];
      const shuffledOptions = [...options].sort(() => Math.random() - 0.5);

      const newAnswerIndices = correctAnswerIndices.map((correctIndex) => {
        return shuffledOptions.findIndex((option) => option === options[correctIndex]);
      });

      return {
        ...question,
        options: shuffledOptions,
        answer: newAnswerIndices,
      };
    });
  };

  useEffect(() => {
    if (timer > 0 && !quizCompleted && quizStarted) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setQuizCompleted(true);
      showFinalResults();
    }
  }, [timer, quizCompleted, quizStarted]);

  const handleCheckboxChange = (index) => {
    const selectedAnswerSet = new Set(
      selectedAnswers[currentQuestionIndex] || []
    );
    if (selectedAnswerSet.has(index)) {
      selectedAnswerSet.delete(index);
    } else {
      selectedAnswerSet.add(index);
    }
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[currentQuestionIndex] = Array.from(selectedAnswerSet);
    setSelectedAnswers(updatedAnswers);
  };

  const handleRadioChange = (index) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[currentQuestionIndex] = [index];
    setSelectedAnswers(updatedAnswers);
  };

  const handleSubmit = () => {
    const currentQuestion = shuffledQuestions[currentQuestionIndex];

    if (!currentQuestion) return;

    const correctAnswers = currentQuestion.answer;
    const selectedArray = selectedAnswers[currentQuestionIndex] || [];
    const isCorrect =
      correctAnswers.every((ans) => selectedArray.includes(ans)) &&
      selectedArray.length === correctAnswers.length;

    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
      setPopupType("success");
      setPopupMessage(`<h2><strong>Correct!</strong></h2>`);
      setTimeout(() => {
        if (currentQuestionIndex < shuffledQuestions.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
          setQuizCompleted(true);
          showFinalResults();
        }
        closePopup();
      }, 400);
    } else {
      setPopupType("error");
      const allOptionsText = currentQuestion.options
        .map((option, index) => {
          return correctAnswers.includes(index)
            ? `&#9989; ${option}`
            : `&#10060; ${option}`;
        })
        .join("\n");

      setPopupMessage(`<strong>Wrong Answer!</strong><hr>${currentQuestion.question}\n\n ${allOptionsText}`);
      setShowPopup(true);
    }
    setShowPopup(true);

    if (currentQuestionIndex < shuffledQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setQuizCompleted(true);
      showFinalResults();
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const showFinalResults = () => {
    const totalQuestions = shuffledQuestions.length;
    const percentageScore = ((score / totalQuestions - 1) * 100).toFixed(2);
    setPopupMessage(`Quiz Completed! Your score: ${score}/${totalQuestions - 1} (${percentageScore}%)`);
    setPopupType("success");
    setShowPopup(true);
  };

  const currentQuestion = shuffledQuestions[currentQuestionIndex];
  const isMultipleAnswers = currentQuestion?.answer.length > 1;

  const totalQuestions = shuffledQuestions.length;
  const percentageScore =
    totalQuestions > 0
      ? ((score / currentQuestionIndex || 0) * 100).toFixed(2)
      : 0;

  const isAnswerSelected =
    selectedAnswers[currentQuestionIndex] &&
    selectedAnswers[currentQuestionIndex].length > 0;

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const handleStart = (questionCount) => {
    const totalQuestions = questionCount === "all" ? QuestionType(reviewerType).length : questionCount;
    setIsAllQuestionsMode(questionCount === "all");
    setShuffledQuestions(randomizeQuestions(totalQuestions));
    setQuizStarted(true);
  };
  const handleNavigateToMatchingQuestion = () => {
    setShowMatchingQuestion(true);
  };

  return (
    <div className="App">
      <h1>{reviewerType}</h1>
      {
        reviewerType !== "" ?
          !quizStarted && !showMatchingQuestion ?
            (
              <StartMenu
                handleStart={handleStart}
                handleNavigateToMatchingQuestion={handleNavigateToMatchingQuestion}
                reviewerType={reviewerType}
              />
            )
            : showMatchingQuestion ?
              (
                <MQ />
              )
              : !quizCompleted ?
                (
                  <Quiz
                    currentQuestion={currentQuestion}
                    currentQuestionIndex={currentQuestionIndex}
                    selectedAnswers={selectedAnswers}
                    isMultipleAnswers={isMultipleAnswers}
                    handleCheckboxChange={handleCheckboxChange}
                    handleRadioChange={handleRadioChange}
                    handleSubmit={handleSubmit}
                    isAnswerSelected={isAnswerSelected}
                  />
                )
                : null
          :
          <MainMenu handleReviewerType={handleReviewerType} />
      }
      <div>
        {(quizStarted || quizCompleted) ? (
          <ScoreDisplay
            percentageScore={percentageScore}
            score={score}
            totalQuestions={totalQuestions}
            minutes={minutes}
            seconds={seconds}
            isAllQuestionsMode={isAllQuestionsMode}
          />
        ) : null}
      </div>
      <Popup
        showPopup={showPopup}
        popupMessage={popupMessage}
        popupType={popupType}
        closePopup={closePopup}
      />
    </div>
  );
};

export default App;
